// required modules
import axios from 'axios'
import Service from '@/services/api/Service'
import GGoogleModel from '@/models/General/GGoogle'

/**
 * This class describes a GGoogle service.
 *
 * @class GGoogle (name)
 */
export default class GGoogleService extends Service {

  /**
   * 
   */
  static access(gGoogle) {
    return axios.request({
      method: 'post',
      url: gGoogle.many('access').$uri,
      headers: {
        authorization: `Bearer ${ gGoogle.authToken }`
      },
      data: {
        code: gGoogle.code,
        redirectUri: gGoogle.redirectUri,
      },
    })
  }

  /**
   * 
   */
  static getAccessType() {
    return 'offline'
  }

  /**
   * 
   */
  static getPrompt() {
    return 'consent'
  }

  /**
   * 
   */
  static getBaseLink() {
    return `https://accounts.google.com/o/oauth2/v2/auth`
  }

  /**
   * 
   */
  static getClientId() {
    return process.env.VUE_APP_GOOGLE_CLIENT_ID
  }

  /**
   * 
   */
  static getLink() {
    return `${ this.getBaseLink() }?response_type=code&client_id=${ this.getClientId() }&scope=${ this.getScope() }&redirect_uri=${ this.getRedirectUri() }&access_type=${ this.getAccessType() }&prompt=${ this.getPrompt() }`
  }

  /**
   * 
   */
  static getRedirectUri() {
    return `https://eindustrial.api.sys.dev.innlab.cl/api/google-access`
  }

  /**
   * 
   */
  static getScope() {
    return `https://www.googleapis.com/auth/userinfo.email https://www.googleapis.com/auth/userinfo.profile https://www.googleapis.com/auth/calendar`
  }

  /**
   * Define the GGoogle model.
   *
   * @return {Function} GGoogle resource.
   */
  static model() {
    return GGoogleModel
  }

  /**
   * 
   */
  static toAccess() {
    return this.shape({
      code: '',
      auth: '',
    })
  }
}