// required modules
import Model from '@/models/Model'

/**
 * This class describes a GGoogle model.
 *
 * @class GGoogle
 */
export default class GGoogleModel extends Model {

  /**
   * Define the GGoogle resource.
   *
   * @return {String} GGoogle endpoint name.
   */
  static resource() {
    return 'google'
  }
}